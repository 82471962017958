import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ApplicationForm = ({ formData, setFormData, handleNextPage }) => {
  const initialValues = {
    contactno: "",
    country_code: "",
  };
  const [referenceData, setReferenceData] = useState(initialValues);

  // const handleChange = (e, value, name) => {
  //   if (name === "contactno") {
  //     let splitMobile = e?.split(value?.dialCode);
  //     setReferenceData({
  //       ...referenceData,
  //       country_code: value?.dialCode,
  //       contactno: splitMobile?.[1] || "",
  //     });
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       parent_mobile: `${referenceData.country_code}${referenceData.contactno}`,
  //     }));
  //   } else
  //     setReferenceData({ ...referenceData, [e.target.name]: e.target.value });
  // };
  const handlePhoneChange = (e, value, name) => {
    console.log("value", value);
    console.log("name", name);
    if (name === "contactno") {
      let splitMobile = e?.split(value?.dialCode);
      const newContactno = splitMobile?.[1] || "";
      setReferenceData({
        ...referenceData,
        country_code: value?.dialCode,
        contactno: newContactno,
      });
      setFormData({
        ...formData,
        phone: `${value?.dialCode}${newContactno}`,
      });
    } else {
      setReferenceData({ ...referenceData, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className="sign-up-container">
      <div className="app_olevel">
        <h2 className="font-semibold text-left sm:font-medium text-xl sm:text-3xl">
          Fill The Application Form
        </h2>
        <div className="olevel_form">
          <div className="olevel_details">
            <label htmlFor="">Full name</label>
            <input
              type="text"
              value={formData.full_name}
              placeholder="Enter your full name"
              onChange={(event) =>
                setFormData({
                  ...formData,
                  full_name: event.target.value,
                })
              }
            />
          </div>
          <div className="flex gap-4 w-full justify-between items-center">
            <div className="olevel_details w-1/2">
              <label htmlFor="">Gender</label>
              <select
                name=""
                id=""
                value={formData.gender}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    gender: event.target.value,
                  })
                }
              >
                <option value="">--Select an Option--</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="prefer not to say">Prefer not to say</option>
              </select>
            </div>

            <div className="olevel_details w-1/2">
              <label htmlFor="">Date of Birth</label>
              <input
                type="date"
                name=""
                id=""
                className="date-time"
                value={formData.dob}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    dob: event.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="olevel_details">
            <label htmlFor="">Current House Address</label>
            <input
              type="text"
              value={formData.current_address}
              placeholder="Example: House 08, Akinyele, Ibadan"
              onChange={(event) =>
                setFormData({
                  ...formData,
                  current_address: event.target.value,
                })
              }
            />
          </div>
          <div className="flex gap-4 w-full justify-between">
            <div className="olevel_details w-1/2">
              <label htmlFor="">Father's Name</label>
              <input
                type="text"
                placeholder="Example: John"
                value={formData.father_name}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    father_name: event.target.value,
                  })
                }
              />
            </div>
            <div className="olevel_details w-1/2">
              <label htmlFor="">Mother's Name</label>
              <input
                type="text"
                placeholder="Example: Doe"
                value={formData.mother_name}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    mother_name: event.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="flex gap-4 w-full  mb-[1.2rem] justify-between items-end">
            <div className="olevel_details w-1/2">
              <label htmlFor="">Phone Number of One Parent</label>
              <PhoneInput
                country="ng"
                regions={"africa"}
                inputStyle={{
                  height: "44px",
                  width: "100%",
                  borderRadius: "8px",
                }}
                buttonStyle={{
                  backgroundColor: "white",
                  borderRadius: "8px 0 0 8px",
                  borderColor: "#66666659",
                }}
                onChange={(e, phone) =>
                  handlePhoneChange(e, phone, "contactno")
                }
                value={`${referenceData.country_code}${referenceData.contactno}`}
              />
            </div>
            <div className="olevel_details w-1/2">
              <label htmlFor="">Whose Number</label>
              <select
                name=""
                id=""
                value={formData.whose_mobile}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    whose_mobile: event.target.value,
                  })
                }
              >
                <option value="">--Select an Option--</option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Guardian">Guardian</option>
              </select>
            </div>
          </div>
        </div>
        <button className="wide-btn btn mt-8" onClick={() => handleNextPage()}>
          Submit Application Form
        </button>
      </div>
    </div>
  );
};

export default ApplicationForm;
