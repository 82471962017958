import "./Documents.css";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../utils/AuthContext";
import { BASE_URL } from "../../constants";
import { toast } from "react-toastify";

const docs = [
  {
    title: "NIN",
    text: "Upload your NIN slip",
    number: "1",
    submitted: "nin_submitted",
  },
  {
    title: "JAMB RESULT",
    text: "Upload your JAMB RESULT",
    number: "2",
    submitted: "jamb_submitted",
  },
  {
    title: "WAEC Certificate",
    text: "Upload your O'level RESULT here",
    number: "3",
    submitted: "waec_submitted",
  },
  {
    title: "NECO Certificate",
    text: "Upload your O'level Result here",
    number: "4",
    submitted: "neco_submitted",
  },
  {
    title: "Passport Photograph",
    text: "Upload your Passport Photograph here",
    number: "5",
    submitted: "passport_submitted",
  },
  {
    title: "Birth Certificate",
    text: "Upload your original copy of your Birth Certificate here",
    number: "6",
    submitted: "birth_certificate_submitted",
  },
  {
    title: "Certificate of Origin",
    text: "Upload your original copy of your Certificate here",
    number: "7",
    submitted: "certificate_origin_submitted",
  },
  {
    title: "Guarantor",
    text: "Upload your Guarantor form here",
    number: "8",
    submitted: "guarantor_submitted",
  },
];

const Documents = () => {
  const [submissionStatus, setSubmissionStatus] = useState([{}]);
  const history = useNavigate();

  const { user, token, signIn, signOut, profileData, setProfileData } =
    useContext(AuthContext);

  if (!profileData) {
    return <div>Loading profile data...</div>;
  }

  const renderDocs = () =>
    docs.map((doc, index) => {
      // Destructure profileData (optional)
      const { [doc.submitted]: isSubmitted } = profileData || {}; // Assuming properties match doc.submitted

      return (
        <div className="doc-div" key={index}>
          <div className="number_title">
            <span className="number">{doc.number}</span>
            <div className="doc_title-div">
              <span className="doc_title">{doc.title}</span>
              <span>{doc.text}</span>
            </div>
          </div>
          <button
            className={`btn btn_upload ${isSubmitted && "btn_upload_success"}`}
          >
            {isSubmitted ? "Uploaded" : "Not Uploaded"}
          </button>
        </div>
      );
    });

  return (
    <div className="flex">
      <div className="margleft">
        <div className="dashboard">
          <p className="doc-header">Your Documents</p>
          <div className="documents">{renderDocs()}</div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
