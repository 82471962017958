import { useEffect, useState } from "react";
import DynamicNav from "../components/DynamicNav/DynamicNav";
import { useNavigate } from "react-router-dom";
import SchoolSideBar from "../components/SchoolSideBar";

const SchoolDashboardLayout = ({ children, title }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const isLoggedIn = localStorage.getItem("schoolToken"); // Check for authToken
  const [type, setType] = useState("school")

  const history = useNavigate();

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  useEffect(() => {
    if (isLoggedIn === "" || isLoggedIn === null) {
      history("/school-signin");
    }
  }, []);

  return (
    <div>
      <div className="margleft">
        <DynamicNav title={title} toggleSideNav={toggleSideNav} type={type} />
      </div>
      <div>
        <SchoolSideBar isOpen={isSideNavOpen} toggleSideNav={toggleSideNav} />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default SchoolDashboardLayout;
