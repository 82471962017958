export const BASE_URL = "https://dev-api.eldanic.com/api/v1/";

// const rows = [
//   createData(
//     "1",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "2",
//     "Victory Blessing",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "3",
//     "Busayo First",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "4",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "5",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "6",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "7",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "8",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "9",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "10",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "11",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "12",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "13",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "14",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
//   createData(
//     "15",
//     "David Johnson",
//     "Covenant University",
//     "Computer Science",
//     "8/10/24",
//     "View Details"
//   ),
// ];
