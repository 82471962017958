// SignIn.js
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/AuthContext";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { BASE_URL } from "../constants";

const SignIn = () => {
  const { user, token, signIn, signOut, profileData, setProfileData } =
    useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visibility, setVisibility] = useState(false);

  const history = useNavigate();

  const passwordShow = () => {
    setVisibility((prev) => !prev);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (validated()) {
      try {
        await fetch(`${BASE_URL}auth/login/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email, password: password }),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === false) {
              toast.error(result.message);
            } else {
              toast.success(result.message);
              sessionStorage.setItem("email", result.data.email);
              localStorage.setItem("authToken", result.data.token);

              // Redirecting to the dashboard or another page upon successful sign-in
              history("/dashboard");
            }
          })
          .catch((error) => console.error(error));
      } catch (error) {
        toast.error("Error during sign-in:", error);
      }
    }
  };

  const validated = () => {
    let result = true;
    let errorMessage = "Please enter a value in ";
    if (email === null || email === "") {
      result = false;
      errorMessage += "email";
    }
    if (password === null || password === "") {
      result = false;
      errorMessage += " password";
    }
    if (!result) {
      toast.warning(errorMessage);
    } else {
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      } else {
        result = false;
        toast.warning("Please enter a valid email");
      }
    }
    return result;
  };

  return (
    <form className="hero-form max-w-[340px] sm:w-[360px] min-h-[452px] flex-shrink-0 rounded-3xl bg-white p-6">
      <p className="red-text text-center mb-6 font-medium text-2xl">
        Login Here
      </p>
      <div className="input-container flex flex-col gap-2 mb-2">
        <label htmlFor="email">Email address</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <p className="error-message"></p>
      </div>
      <div className="input-container">
        <label htmlFor="password">Password</label>
        <span className="password-visibility">
          <input
            type={visibility ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {!visibility ? (
            <>
              <FaEye onClick={() => passwordShow()} cursor={"pointer"} />{" "}
            </>
          ) : (
            <>
              <FaEyeSlash onClick={() => passwordShow()} cursor={"pointer"} />{" "}
            </>
          )}
        </span>
        <p className="error-message"></p>
      </div>
      <div className="forgot_div">
        <div className="check-div">
          <input type="checkbox" className="check-box" />
          <p className="remember">Remember me</p>
        </div>
        {/* link for forgot password /forgot-password */}
        <Link to="/forgot-password" className="underlined">
          Forgot Password
        </Link>
      </div>
      <div className="login-btn">
        <button onClick={handleSignIn} className="small-btn">
          Login
        </button>
        <p>
          Don't have an account? <br />
          {/* link to sign up /signup */}
          <Link to="/signup" className="underlined red-text">
            Sign up
          </Link>{" "}
          here
        </p>
      </div>
    </form>
  );
};

export default SignIn;
