import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/5.png";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  FaEye,
  FaEyeSlash,
  FaExclamationCircle,
  FaRegCheckCircle,
  FaRegCircle,
} from "react-icons/fa";
import { BASE_URL } from "../../../constants";
import Background from "../../../components/Background/Background";
import GoogleAuth from "../../../components/GoogleAuth";

const SchoolSignUp = () => {
  const [email, setEmail] = useState("");
  const [schoolAddress, setschoolAddress] = useState("");
  const [schoolName, setschoolName] = useState("");
  const [schoolCategory, setschoolCategory] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [validationResults, setValidationResults] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
  });
  const history = useNavigate();

  const passwordShow = () => {
    setVisibility((prev) => !prev);
  };

  const clientId =
    "700918527543-o711lm59d7t83amp3ccmh5jhhcvreins.apps.googleusercontent.com";

  const updateValidationResults = () => {
    const lengthValid = password.length >= 8;
    const uppercaseValid = /[A-Z]/.test(password);
    const lowercaseValid = /[a-z]/.test(password);
    const specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setValidationResults({
      length: lengthValid,
      uppercase: uppercaseValid,
      lowercase: lowercaseValid,
      specialChar: specialCharValid,
    });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    updateValidationResults();
  }, [password]);

  const isValidated = () => {
    const isEmailValid = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
    const isPasswordValid = Object.values(validationResults).every(
      (valid) => valid
    );

    if (!isEmailValid) {
      toast.warning("Please enter a valid email");
    }

    if (!isPasswordValid) {
      toast.warning("Password does not meet the criteria");
    }

    return isEmailValid && isPasswordValid && email !== "" && password !== "";
  };

  const handleSchoolChange = (event) => {
    setschoolCategory(event.target.value);
    console.log(schoolCategory);
  };

  const postData = (e) => {
    e.preventDefault();
    toast.loading("Loading...");

    if (isValidated()) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        school_email: email,
        school_address: schoolAddress,
        school_category: schoolCategory,
        school_name: schoolName,
        password: password,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BASE_URL}school/register/`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            console.log(result);
            toast.dismiss();
            toast.error(result.message);
          } else {
            toast.dismiss();
            toast.success(result.message);
            console.log(result);
            history("/school-signin");
          }
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <div>
      <div className="absolute">
        <Background />
      </div>
      <div className="form-nav-logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="signup__main">
        <p className="form-title">School Sign Up</p>
        <form>
          <div className="input-container">
            <label htmlFor="email">Email address</label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <p className="error-message">{error}</p>
          </div>
          <div className="input-container">
            <label htmlFor="school_address">School address</label>
            <input
              type="text"
              id="school_address"
              value={schoolAddress}
              onChange={(e) => {
                setschoolAddress(e.target.value);
              }}
            />
            <p className="error-message">{error}</p>
          </div>
          <div className="input-container">
            <label htmlFor="school_name">School Name</label>
            <input
              type="text"
              id="school_name"
              value={schoolName}
              onChange={(e) => {
                setschoolName(e.target.value);
              }}
            />
            <p className="error-message">{error}</p>
          </div>
          <div className="input-container">
            {/* <div className="uniType flex flex-col gap-2"> */}
            <label htmlFor="category">Category</label>
            <select
              name="category"
              id="category"
              value={schoolCategory}
              onChange={handleSchoolChange}
            >
              <option value="">--Select School Category--</option>
              <option value="state_university">State University</option>
              <option value="state_college">State College</option>
              <option value="private_university">Private University</option>
              <option value="federal_university">Federal University</option>
              <option value="federal_college">Federal College</option>
            </select>
            {/* </div> */}
          </div>
          <div className="input-container">
            <label htmlFor="">Enter a Password</label>
            <span className="password-visibility">
              <input
                type={visibility ? "text" : "password"}
                id="password"
                value={password}
                onChange={handlePasswordChange}
              />
              {!visibility ? (
                <>
                  <FaEye onClick={() => passwordShow()} cursor={"pointer"} />{" "}
                </>
              ) : (
                <>
                  <FaEyeSlash
                    onClick={() => passwordShow()}
                    cursor={"pointer"}
                  />{" "}
                </>
              )}
            </span>
            <div className="tracker-box">
              <div>
                At least 8 characters
                {validationResults.length ? (
                  <FaRegCheckCircle color="green" />
                ) : (
                  <FaExclamationCircle color="orange" />
                )}
              </div>
              <div>
                At least one lowercase letter
                {validationResults.lowercase ? (
                  <FaRegCheckCircle color="green" />
                ) : (
                  <FaExclamationCircle color="orange" />
                )}
              </div>
              <div>
                At least one uppercase letter
                {validationResults.uppercase ? (
                  <FaRegCheckCircle color="green" />
                ) : (
                  <FaExclamationCircle color="orange" />
                )}
              </div>
              <div>
                At least one special character
                {validationResults.specialChar ? (
                  <FaRegCheckCircle color="green" />
                ) : (
                  <FaExclamationCircle color="orange" />
                )}
              </div>
            </div>
            <p className="error-message">{error}</p>
          </div>

          {/* PASSWORD CHECK */}
          <div className="check-div">
            <input type="checkbox" className="check-box" />
            <p className="remember">Remember me</p>
          </div>

          {/* TERMS AND CONDITIONS */}
          <p className="terms">
            By continuing, you agree to the{" "}
            <Link
              target="_blank"
              to="https://proconnectpay.com/#terms-and-conditions"
            >
              <span className="underlined">Terms and conditions</span>
            </Link>{" "}
            and{" "}
            <Link
              target="_blank"
              to="https://proconnectpay.com/#terms-and-conditions"
            >
              <span className="underlined">Privacy Policy</span>
            </Link>
          </p>

          <button
            type="button"
            className="btn wide-btn sign__up bg-red-600"
            onClick={postData}
          >
            Sign Up
          </button>
        </form>
        <p className="center question">
          Do you have an account?{" "}
          <span className="underlined login">
            <Link to="/school-signin">Login</Link>{" "}
          </span>
        </p>
        {/* <span className="continue-div">
          <hr />
          <p className="continue center">Or continue with</p>
          <hr />
        </span>
        <GoogleAuth /> */}
        {/* <GoogleSignInButton /> */}
      </div>
    </div>
  );
};

export default SchoolSignUp;
