import React, { useContext, useState } from "react";
import "./Payment.css";
import { toast } from "react-toastify";
import PaystackPop from "@paystack/inline-js";
import { BASE_URL } from "../../constants";
import { AuthContext } from "../../utils/AuthContext";

const Payment = () => {
  const [appId, setAppId] = useState("");
  const [errors, setErrors] = useState("");

  const { token } = useContext(AuthContext);

  const handlePayment = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Token ${token}`);

    const raw = JSON.stringify({
      callback_url: "http://localhost:5173/#/dashboard/",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}application/pay/${appId}/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          // Redirect to the payment URL
          window.location.href = result.data.payment_url;
        } else {
          // Handle error or invalid status
          setErrors(result.message);
          console.error("Payment link is not ready:", result.message);
        }
      })
      .catch((error) => console.error("Fetch error:", error));
  };

  return (
    <div>
      <div className="app_payment">
        <h2 className="font-medium text-3xl">Pay School Application Fee</h2>
        <div className="payment_form">
          <div className="payment_input">
            <label htmlFor="appId">Enter Your Application Id</label>
            <input
              type="text"
              value={appId}
              onChange={(e) => setAppId(e.target.value)}
              className="m-0"
              name="appId"
              placeholder="eg: ELDA-7423754324"
              // ELDA-9269581850
            />
            <p className="text-red-500">{errors}</p>
          </div>
          <button className="btn wide-btn" onClick={handlePayment}>
            Make Payment
          </button>
          <p className="payment_text mt-8">
            FINAL SUBMISSION AND ACKNOWLEDGEMENT EMAIL SENT TO LET YOU KNOW NEXT
            STEPS AND EXPECTATION
          </p>
        </div>
      </div>
    </div>
  );
};

export default Payment;
