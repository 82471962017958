import React from "react";

const CourseOptions = ({ formData, setFormData, handleNextPage }) => {
  const handleChange = (event) => {
    const selectedCourse = event.target.value;
    console.log("Selected Course:", selectedCourse);
    setFormData((prevData) => ({
      ...prevData,
      course: selectedCourse,
    }));
  };

  return (
    <div className="sign-up-container">
      <div className="app_apply">
        <h2 className="font-semibold text-left sm:font-medium text-xl sm:text-3xl">
          Course Options
        </h2>
        <div className="uniType flex flex-col gap-2">
          <label htmlFor="" className="text-lg">
            Courses
          </label>
          <select name="" id="" onChange={handleChange}>
            <option value="">--Select a course--</option>
            <option value="B.Sc Mathematics">B.Sc Mathematics</option>
            <option value="B.sc Chemistry">B.sc Chemistry</option>
            <option value="B.Sc Microbiology">B.Sc Microbiology</option>
            <option value="B.Sc Marine Science">B.Sc Marine Science</option>
            <option value="B.Sc Industrial Chemistry">
              B.Sc Industrial Chemistry
            </option>
            <option value="B.sc Estate Management">
              B.sc Estate Management
            </option>
            <option value="B.Sc Building">B.Sc Building</option>
            <option value="B.Sc Urban and Regional Planning">
              B.Sc Urban and Regional Planning
            </option>
          </select>
        </div>
        <button className="wide-btn btn mt-8" onClick={() => handleNextPage()}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CourseOptions;
