import React, { useContext } from "react";
import SuccessImg from "../../assets/images/successful.png";
import "./Success.css";
import { AuthContext } from "../../utils/AuthContext";
import { BASE_URL } from "../../constants";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Success({ handleNextPage }) {
  // const { token } = useContext(AuthContext);
  // const appId = localStorage.getItem("applicationId");
  // const handlePayment = () => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", `Token ${token}`);

  //   const raw = JSON.stringify({
  //     callback_url: "http://localhost:5173/#/dashboard/",
  //   });

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(`${BASE_URL}application/pay/${appId}/`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status) {
  //         // Redirect to the payment URL
  //         window.location.href = result.data.payment_url;
  //       } else {
  //         // Handle error or invalid status
  //         toast.error("You need an application ID to make payment");
  //         console.error("Payment link is not ready:", result.message);
  //       }
  //     })
  //     .catch((error) => console.error("Fetch error:", error));
  // };

  return (
    <div className="sign-up-container">
      <div className="app_successful">
        <h2 className="font-semibold text-center sm:font-medium text-xl sm:text-3xl">
          Application Sent Successfully
        </h2>
        <div className="successful">
          <img src={SuccessImg} alt="" />
        </div>
        <div className="flex gap-4 items-center mt-8">
          <button
            className="bg-[#db251a] px-4 py-2 text-sm rounded-lg text-white font-medium"
            onClick={handleNextPage}
          >
            Proceed to pay school application fee
          </button>
          <Link
            to="/dashboard"
            className="bg-[#db251a] px-4 py-2 text-sm rounded-lg text-white font-medium"
          >
            <button>Return to dashboard</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Success;
