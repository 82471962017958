import { HashRouter, Route, Routes } from "react-router-dom";
import Form from "./components/Form/Form";
import "./App.css";
import { ToastContainer } from "react-toastify";
import FormLayout from "./layout/FormLayout";
import DashboardLayout from "./layout/DashboardLayout";
import {
  AppDetails,
  ApplicationChecklist,
  ApplicationStatus,
  Candidates,
  ChangePassword,
  Dashboard,
  Documents,
  ErrorPage,
  ForgotPassword,
  Help,
  Home,
  Profile,
  RecommenderForm,
  ResetPassword,
  SchoolProfile,
  SchoolSignIn,
  SchoolSignUp,
  SignUp,
  StudentDashboard,
  SupportedSchools,
} from "./pages";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://ceb3f7c1be6568be7b6ee72874b8e3e1@o4507236342038528.ingest.de.sentry.io/4507456174948432",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <>
      <ToastContainer theme={"colored"}></ToastContainer>
      <HashRouter>
        <Routes>
          {/* SCHOOL ROUTES */}
          <Route path="/school-signup" element={<SchoolSignUp />} />
          <Route path="/school-signin" element={<SchoolSignIn />} />
          <Route path="/applications/:appId" element={<AppDetails />} />
          <Route path="/school-profile" element={<SchoolProfile />} />
          <Route path="/applications" element={<ApplicationStatus />} />
          <Route path="/candidates" element={<Candidates />} />
          <Route path="/school-dashboard" element={<StudentDashboard />} />

          {/* STUDENT ROUTES */}
          <Route path="/" element={<Home />} />
          <Route
            path="/dashboard"
            element={
              <DashboardLayout title={"Dashboard"}>
                <Dashboard />
              </DashboardLayout>
            }
          />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/application-checklist"
            element={
              <DashboardLayout title={"Application Checklist"}>
                <ApplicationChecklist />
              </DashboardLayout>
            }
          />
          <Route
            path="/supported-schools"
            element={
              <DashboardLayout title={"Supported Schools"}>
                <SupportedSchools />
              </DashboardLayout>
            }
          />
          <Route
            path="/documents"
            element={
              <DashboardLayout title={"Documents"}>
                <Documents />
              </DashboardLayout>
            }
          />

          <Route
            path="/profile"
            element={
              <DashboardLayout title={"Profile"}>
                <Profile />
              </DashboardLayout>
            }
          />
          <Route
            path="/help"
            element={
              <DashboardLayout title={"Help"}>
                <Help />
              </DashboardLayout>
            }
          />
          <Route
            path="/school-application"
            element={
              <FormLayout>
                <Form />
              </FormLayout>
            }
          />
          <Route
            path="/recommender-form"
            element={
              <FormLayout>
                <RecommenderForm />
              </FormLayout>
            }
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />

          {/* 404 PAGE */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
