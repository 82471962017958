import React, { useContext, useState } from "react";
import "./NIN.css";
import { toast } from "react-toastify";
import { AuthContext } from "../../utils/AuthContext";
import { BASE_URL } from "../../constants";

function NIN({ formData, setFormData, onButtonClick, handleNextPage }) {
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [nin, setNin] = useState("");
  const [name, setName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [houseAddress, setHouseAddress] = useState("");
  const [gender, setGender] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [verificationOption, setVerificationOption] = useState("nin");
  const [setstateOfOrigin, setSetstateOfOrigin] = useState("");
  const [error, setError] = useState(false);

  const { user, token, signIn, signOut, profileData, setProfileData } =
    useContext(AuthContext); // Access the user object from AuthContext

  const handleValidation = () => {
    toast.loading("Validating...");
    const formattedDate = displayLocalDate();
    fetch(`${BASE_URL}user/fetch-nin/?nin_number=${nin}&dob=${formattedDate}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("ninData", JSON.stringify(data));
        toast.dismiss();
        setName(
          data.data.first_name +
            " " +
            data.data.middle_name +
            " " +
            data.data.last_name
        );
        setDateOfBirth(data.data.date_of_birth);
        setGender(data.data.gender);
        toast.success(data.message);
        setValidated(true);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("There was a problem fetching your details:", error);
        setError(true);
      });
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value;

    setSelectedDate(newDate);
  };

  const displayLocalDate = () => {
    // Convert UTC date string to local date with time zone offset
    const localDate = new Date(selectedDate + "T00:00:00");
    const offset = localDate.getTimezoneOffset() * 60000; // in milliseconds
    const localDateWithOffset = new Date(localDate - offset);
    return localDateWithOffset.toISOString().split("T")[0];
  };

  return (
    <div className="sign-up-container">
      <div className="app_nin">
        <div className={validated ? "none" : "nin-container"}>
          <h2 className="font-semibold text-left sm:font-medium text-xl sm:text-3xl">
            Input your NIN
          </h2>
          <div>
            <label htmlFor="verification-option" className="mb-4">
              Choose your form of verification
            </label>
            <select
              id="verification-option"
              value={verificationOption}
              onChange={(e) => setVerificationOption(e.target.value)}
            >
              <option value="nin">NIN Number</option>
              <option value="passport">International Passport</option>
            </select>
          </div>
          <div className="flex gap-4 w-full justify-between items-end">
            <div className="w-1/2">
              <label htmlFor="verification-input">
                Input your
                {verificationOption === "nin"
                  ? " NIN number"
                  : " Passport number"}
              </label>
              <input
                type="text"
                id="verification-input"
                placeholder="Example: 1000000000"
                value={nin}
                onChange={(e) => setNin(e.target.value)}
              />
            </div>
            <div className="w-1/2">
              <label htmlFor="">Date of Birth</label>
              <input
                type="date"
                id="datePicker"
                name="datePicker"
                value={selectedDate}
                onChange={handleDateChange}
                className="date-time w-full"
              />
              {/* {selectedDate && <p>Selected Date: {displayLocalDate()}</p>} */}
            </div>
          </div>
          {error ? (
            <p className="text-red-500 text-sm">Check your NIN and try again</p>
          ) : (
            ""
          )}
          <button className="wide-btn btn" onClick={() => handleNextPage()}>Next</button>
          {!validated && (
            <button
              className="wide-btn btn font-medium"
              onClick={handleValidation}
            >
              Validate
            </button>
          )}
        </div>
        {validated && (
          <div>
            <h2>Ready your NIN</h2>
            <p className="info_nin">Your Information</p>
            <div className="nin-info">
              <div className="nin-text">
                <p className="title">Full Name</p>
                <p className="nin-detail">{name}</p>
              </div>
              <div className="nin-text">
                <p className="title">Date of Birth</p>
                <p className="nin-detail">{dateOfBirth}</p>
              </div>

              <div className="nin-text">
                <p className="title">Gender</p>
                <p className="nin-detail">{gender}</p>
              </div>
            </div>
            <button className="wide-btn btn mt-8" onClick={() => handleNextPage()}>Next</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default NIN;
