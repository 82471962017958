import React from "react";
import SchoolDashboardLayout from "../../layout/SchoolDashboardLayout";

const ApplicationStatus = () => {
  return (
    <SchoolDashboardLayout title="Application Overview">
      <div className="margleft dashboard flex gap-8 flex-col">
        <p>Select an application to view more details</p>
      </div>
    </SchoolDashboardLayout>
  );
};

export default ApplicationStatus;
