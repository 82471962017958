import React, { useState, useEffect, useContext } from "react";
import Success from "../Success/Success";
import NIN from "../NIN/NIN";
import Payment from "../Payment/Payment";
import "./Form.css";
import Olevel from "../Olevel/Olevel";
import ReturnBtn from "../../assets/images/Iconsback.png";
import Jamb from "../Jamb/Jamb";
import ApplyingTo from "../ApplyingTo/ApplyingTo";
import NameOfUni from "../NameOfUni/NameOfUni";
import WatchVideo from "../WatchVideo/WatchVideo";
import CourseOptions from "../CourseOptions/CourseOptions";
import CourseRequirements from "../CourseRequirements/CourseRequirements";
import ReferenceInfo from "../ReferenceInfo/ReferenceInfo";
import DocumentsUpload from "../DocumentsUpload/DocumentsUpload";
import FinanceOptions from "../FinanceOptions/FinanceOptions";
import EducationLoans from "../EducationLoans/EducationLoans";
import LoanCalculator from "../LoanCalculator/LoanCalculator";
import ApplicationForm from "../ApplicationForm/ApplicationForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Review from "../../containers/Review/Review";
import { AuthContext } from "../../utils/AuthContext";
import { BASE_URL } from "../../constants";
import OlevelCombo from "../OlevelCombo";

function Form() {
  // const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    const pageFromUrl = parseInt(searchParams.get("page"), 10);
    return !isNaN(pageFromUrl) && pageFromUrl >= 0 ? pageFromUrl : 0;
  });
  const [selectedCategory, setSelectedCategory] =
    useState("Private University");
  const [formData, setFormData] = useState({
    school: "",
    course: "",
    financing: "",
    loan_type: "",
    loan_amount: 0,
    full_name: "",
    gender: "",
    dob: "",
    current_address: "",
    father_name: "",
    mother_name: "",
    parent_mobile: "",
    whose_mobile: "",
    rel_name: "",
    email_address: "",
    phone: "",
    profession: "",
    relationship: "",
  });
  const [hasOwnButton, setHasOwnButton] = useState(false);
  const [nextStep, setNextStep] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page"), 10);
    if (!isNaN(pageFromUrl) && pageFromUrl >= 0 && pageFromUrl !== page) {
      setPage(pageFromUrl);
    }
  }, [searchParams]);

  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page"), 10);
    if (currentPage !== page) {
      setSearchParams({ page });
    }
  }, [page, setSearchParams]);

  // FORM TITLES
  const FormTitles = [
    "Payment",
    "NIN",
    "Olevel",
    "Jamb",
    "Applying to",
    "Name of uni",
    "watch video",
    "course options",
    "course requirements",
    "Reference Info",
    "Upload Documents",
    "Finance Options",
    "Education Loan",
    "Loan Calculator",
    "Review",
    "Application Form",
    "Success",
  ];

  const { token } = useContext(AuthContext);

  // GETTING STORED DATA
  useEffect(() => {
    // Load saved form data and current page from localStorage on component mount
    const savedFormData = JSON.parse(localStorage.getItem("formData"));
    // const savedPage = parseInt(localStorage.getItem("currentPage"), 10);
    const savedHasOwnButton = localStorage.getItem("hasOwnButton") === "true";

    if (savedFormData) {
      setFormData(savedFormData);
    }

    // if (!isNaN(savedPage)) {
    //   setPage(savedPage);
    // }
    setHasOwnButton(savedHasOwnButton);
  }, []);

  // STORING DATA TO LOCAL STORAGE
  useEffect(() => {
    // Save form data, current page, and hasOwnButton to localStorage whenever they change
    localStorage.setItem("formData", JSON.stringify(formData));
    // localStorage.setItem("currentPage", String(page));
    localStorage.setItem("hasOwnButton", String(hasOwnButton));
  }, [formData, page, hasOwnButton]);

  // HANDLE PAGE CHANGE
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setHasOwnButton(false);
  };

  // FORM SUBMISSION
  const handleFormSubmission = () => {
    toast.loading("Submitting");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Token ${token}`);

    const raw = JSON.stringify({
      school: formData.school,
      course: formData.course,
      financing: formData.financing,
      loan_type: formData.loan_type,
      loan_amount: formData.loan_amount,
      full_name: formData.full_name,
      gender: formData.gender,
      dob: formData.dob,
      current_address: formData.current_address,
      father_name: formData.father_name,
      mother_name: formData.mother_name,
      parent_mobile: formData.parent_mobile,
      whose_mobile: formData.whose_mobile,
      rel_name: formData.rel_name,
      email_address: formData.email_address,
      phone: formData.phone,
      profession: formData.profession,
      relationship: formData.relationship,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}application/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === false) {
          toast.dismiss();
          toast.error(result.message);
        } else {
          toast.success(result.message);
          handlePageChange(page + 1);
          localStorage.setItem("profileStatus", result.data.status);
          localStorage.setItem("applicationId", result.data.application_id);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error);
      });
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  // BUTTON RENDERING
  const renderButtons = () => {
    if (page === 0) {
      return null; // Hide button on the first page
    } else {
      return (
        <button onClick={() => handlePageChange(page - 1)} className="prev-btn">
          <img src={ReturnBtn} alt="Previous" />
        </button>
      );
    }
  };

  // HANDLE PAYMENT
  const handlePayment = (paymentData) => {
    setPage(nextStep);
    setNextStep(1);
  };

  const handleReview = () => {
    handlePageChange(page + 1);
  };

  // PAGE DISPLAY
  const PageDisplay = () => {
    let component;
    let hasOwnButton = false;

    if (page === 0) {
      component = (
        <NIN
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 1) {
      component = (
        <OlevelCombo
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 2) {
      component = <Olevel handleNextPage={handleNextPage} />;
    } else if (page === 3) {
      component = <Jamb handleNextPage={handleNextPage} />;
    } else if (page === 4) {
      component = (
        <ApplyingTo
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 5) {
      component = (
        <NameOfUni
          formData={formData}
          setFormData={setFormData}
          selectedCategory={selectedCategory}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 6) {
      component = (
        <WatchVideo
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 7) {
      component = (
        <CourseOptions
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 8) {
      component = (
        <CourseRequirements
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 9) {
      component = (
        <ReferenceInfo
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 10) {
      component = (
        <DocumentsUpload
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 11) {
      component = (
        <FinanceOptions
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 12) {
      component = (
        <EducationLoans
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 13) {
      component = (
        <LoanCalculator
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 14) {
      component = (
        <Review
          formData={formData}
          setFormData={setFormData}
          onNextButtonClick={handleReview}
          handleNextPage={handleNextPage}
        />
      );
    } else if (page === 15) {
      component = (
        <ApplicationForm
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleFormSubmission}
          // handleNextPage={handleNextPage}
        />
      );
    } else if (page === 16) {
      component = (
        <Success
          formData={formData}
          setFormData={setFormData}
          handleNextPage={handleNextPage}
        />
      );
    } else {
      // Only show payment page if user hasn't paid yet
      component = (
        <Payment
          formData={formData}
          setFormData={setFormData}
          onButtonClick={() => setHasOwnButton(true)}
          handlePayment={handlePayment}
          handleNextPage={handleNextPage}
        />
      );
    }

    return { component, hasOwnButton };
  };

  return (
    <div className="form">
      <div className="form-container">
        <div className="body">{PageDisplay().component}</div>
        <div className="footer">{renderButtons()}</div>
      </div>
    </div>
  );
}

export default Form;
