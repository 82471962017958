import React from "react";
import Logo from "../../assets/images/elda-logo.png";
import Background from "../Background/Background";
import { Link } from "react-router-dom";
import "./WatchVideo.css";

const WatchVideo = ({ formData, setFormData, handleNextPage }) => {
  return (
    <div>
      <div className="app_apply app_video">
        <h2 className="font-semibold text-left sm:font-medium text-xl sm:text-3xl">Watch this video before choosing a course</h2>
        <video controls={true} src=""></video>
      <button className="wide-btn btn mt-8" onClick={() => handleNextPage()}>
        Next
      </button>
      </div>
    </div>
  );
};

export default WatchVideo;
