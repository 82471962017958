import React, { useContext, useEffect, useState } from "react";
import TableLayout from "../../containers/TableLayout";
import SchoolDashboardLayout from "../../layout/SchoolDashboardLayout";
import { AuthContext } from "../../utils/AuthContext";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants";

function createData(serial, name, institution, course, date, action) {
  return { serial, name, institution, course, date, action };
}

const columns = [
  { id: "id", label: "S/N", minWidth: 100 },
  { id: "full_name", label: "Student Name", minWidth: 100 },
  { id: "school", label: "Institution", minWidth: 170, align: "center" },
  { id: "course", label: "Course", minWidth: 170, align: "right" },
  { id: "date", label: "Date", minWidth: 100, align: "right" },
  { id: "status", label: "Status", minWidth: 100, align: "center" },
  // { id: "action", label: "Action", minWidth: 100, align: "center" },
];

const Candidates = () => {
  const [rowData, setRowData] = useState([]);
  const { schoolToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();

  useEffect(() => {
    if (schoolToken !== null) {
      setLoading(false);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Token ${schoolToken}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BASE_URL}school/applications/`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setRowData(result.data);
          console.log(result.data);
        })
        .catch((error) => console.error(error));
    }
  }, [schoolToken]);

  return (
    <SchoolDashboardLayout title={"Candidates"}>
      <div className="margleft dashboard">
        <p className="doc-header mb-8">See all Candidates</p>

        <TableLayout rowData={rowData} columns={columns} />
      </div>
    </SchoolDashboardLayout>
  );
};

export default Candidates;
