import { useContext, useState } from "react";
import "./DynamicNav.css";
import Profile from "../../assets/images/Profile.png";
import Down from "../../assets/images/chevron_down.png";
import Bell from "../../assets/images/notification icon.png";
import { AuthContext, UserContext } from "../../utils/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { MdMenu } from "react-icons/md";

const DynamicNav = (props) => {
  // const { user, signOut } = useAuth();
  const { toggleSideNav } = props;

  const { user, token, signIn, signOut, profileData, setProfileData } =
    useContext(AuthContext);

  const email = sessionStorage.getItem("email");

  const history = useNavigate();
  const handleSignOut = () => {
    // Check if schoolToken exists in localStorage
    if (localStorage.getItem("schoolToken")) {
      // Redirect to another page if schoolToken exists
      history("/school-signin");
    } else {
      // Redirect to the home page if schoolToken does not exist
      history("/");
    }
    // Sign out using AuthContext
    signOut();
  };

  return (
    <div className="dynamic">
      <h2>{props.title}</h2>
      {/* {user && ( */}
      <div className="dynamic-end">
        <span className="profile-bg">
          <Link to={props.type === "school" ? "/school-profile" : "/profile"}>
            {profileData ? (
              <img src={profileData.profile_photo || Profile} alt="Profile" />
            ) : (
              <img src={Profile} alt="Fallback Profile" />
            )}
          </Link>
        </span>
        <p className="usermail">
          {profileData ? profileData.user.username : email}
        </p>
        
        {/* <img className="chevron-down" src={Down} alt="chevron-down" />
        <span className="notifs">
          <img src={Bell} alt="notification-bell" />
          <span className="notif-circle"></span>
        </span> */}
        <button
          onClick={handleSignOut}
          // className="border rounded-md border-black"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="mobile-menu_btn">
          <MdMenu onClick={() => toggleSideNav()} />
        </div>
      </div>
      {/* // )} */}
    </div>
  );
};

export default DynamicNav;
