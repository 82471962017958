import { useContext, useState } from "react";
import { BASE_URL } from "../../constants";
import { AuthContext } from "../../utils/AuthContext";
import "./Help.css";
import { toast } from "react-toastify";

const Help = () => {
  const [message, setMessage] = useState("");
  const { user, token, signIn, signOut, profileData, setProfileData } =
    useContext(AuthContext);

  const sendMessage = (e) => {
    e.preventDefault();
    toast.loading("Loading...");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Token ${token}`);

    const raw = JSON.stringify({
      message: "Thank you for your time",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}contact/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        toast.dismiss();
        toast.success("Sent successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.dismiss();
      });
  };

  return (
    <div className="flex">
      <div className="margleft">
        <div className="dashboard">
          <div className="dashboard_lower">
            <p className="explainer text-2xl">Get In touch</p>
            <form action="" className="help_form">
              <div className="">
                <label htmlFor="email">Full Name</label>
                <input type="text" id="email" name="email" className="" />
              </div>
              <div className="">
                <label htmlFor="email">Email Address</label>
                <input type="email" id="email" name="email" className="" />
              </div>
              <div>
                <label htmlFor="">Message</label>
                <textarea
                  name=""
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button className="btn wide-btn" onClick={sendMessage}>
                Send a Message
              </button>
              <div className="center">or</div>
              <a href="mailto:sales@proconnectpay.com">
                <div className="btn outline">Contact Sales</div>
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
