import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "./utils/AuthContext";
import { BASE_URL } from "./constants";

// const googleId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const googleId =
  "700918527543-o711lm59d7t83amp3ccmh5jhhcvreins.apps.googleusercontent.com";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const GoogleAuthBackend = () => {
  const { token } = useContext(AuthContext);

  const googleButton = useRef(null);

  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    const id = googleId;

    loadScript(src)
      .then(() => {
        /*global google*/

        google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: "outline",
          size: "large",
        });
      })
      .catch(console.error);

    // return () => {
    //   const scriptTag = document.querySelector(`script[src="${src}"]`);
    //   if (scriptTag) document.body.removeChild(scriptTag);
    // };
    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag && scriptTag.parentNode) {
        scriptTag.parentNode.removeChild(scriptTag);
      }
    };
  }, []);

  function handleCredentialResponse(response) {
    if (response.credential) {
      console.log(response.credential);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        auth_token: response.credential,
        user_type: "student",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BASE_URL}auth/google/`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          console.log(result.data);
        })
        .catch((error) => console.error(error));
    }
  }

  return (
    <div id="google-login-btn">
      <div ref={googleButton} id="google-ref"></div>
    </div>
  );
};

export default GoogleAuthBackend;
