import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../constants";
import { AuthContext } from "../../utils/AuthContext";
import GreenTick from "../../assets/images/greentick.png";
import GalleryAdd from "../../assets/images/gallery-add.png";
import SchoolDashboardLayout from "../../layout/SchoolDashboardLayout";
import { Button } from "@mui/material";
import { MdClose } from "react-icons/md";
import TransitionsModal from "../../components/Modal";

const AppDetails = () => {
  const { appId } = useParams();
  const [application, setApplication] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { schoolToken } = useContext(AuthContext);

  useEffect(() => {
    // Fetch application details from the API
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${schoolToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${BASE_URL}school/application/${appId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Set the fetched data to the state
        setApplication(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [appId]);

  if (!application) {
    return <div>Loading...</div>;
  }

  const applicationActionButton = (action, reason) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Token ${schoolToken}`);

    const payload = { action };
    if (action === "decline") {
      payload.reason = reason;
    }

    const raw = JSON.stringify(payload);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}school/application/${appId}/`, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const appPicture = application.personal_info.profile_picture;

  return (
    <SchoolDashboardLayout title="Student Application Details">
      <div className="margleft dashboard flex gap-8 flex-col">
        {/* MODAL FOR ACTION */}
      <TransitionsModal open={open} handleClose={handleClose} />
        {/* Take Action div */}
        <div>
          <p>Take an Action</p>
          <div className="flex items-center justify-between gap-4 md:gap-12 mt-4">
            <Button
              className="w-1/2"
              variant="secondary"
              sx={{
                bgcolor: "#D5F4EA",
                color: "#2A6350",
                textTransform: "capitalize",
              }}
              // onClick={() => applicationActionButton("approve")}
              onClick={handleOpen}
            >
              Approve Student
            </Button>
            <Button
              className="w-1/2"
              variant="secondary"
              sx={{
                bgcolor: "#DB251A1a",
                color: "#DB251A",
                textTransform: "capitalize",
              }}
              // onClick={() =>
              //   applicationActionButton(
              //     "decline",
              //     "The application does not match the reference."
              //   )
              // }
              onClick={handleOpen}
            >
              Decline Student
            </Button>
          </div>
        </div>

        {/* PENDING DIV */}
        <div className="w-full flex items-center justify-center bg-blue-600 rounded-lg h-14 text-white font-semibold">
          Application {application.status}
        </div>

        {/* PESRONAL INFORMATION */}
        <div className="w-full border border-gray-400 rounded-xl min-h-20 p-6 flex flex-col gap-4">
          <p className="text-red-500 font-medium text-3xl">
            Personal Information
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-between">
            <div className="flex flex-col gap-4">
              <span className="flex">
                <p className="w-[150px]">Name</p>
                <p className="text-left font-semibold capitalize">
                  {application.full_name}
                </p>
              </span>
              <span className="flex">
                <p className="w-[150px]">NIN</p>
                <p className="text-left font-semibold">*********</p>
              </span>
              <span className="flex">
                <p className="w-[150px]">Gender</p>
                <p className="text-left font-semibold">{application.gender}</p>
              </span>
              <span className="flex">
                <p className="w-[150px]">Date Of Birth</p>
                <p className="text-left font-semibold">{application.dob}</p>
              </span>
              <span className="flex">
                <p className="w-[150px]">Phone Number</p>
                <p className="text-left font-semibold">{application.phone}</p>
              </span>
              <span className="flex">
                <p className="w-[150px]">Address</p>
                <p className="text-left font-semibold">
                  {application.current_address}
                </p>
              </span>
              <span className="flex">
                <p className="w-[150px]">Father's Name</p>
                <p className="text-left font-semibold">
                  {application.father_name}
                </p>
              </span>
              <span className="flex">
                <p className="w-[150px]">Mother's Name</p>
                <p className="text-left font-semibold">
                  {application.mother_name}
                </p>
              </span>
            </div>
            <div className="profile-pics">
              <span>
                <div className="pics-upload">
                  {appPicture !== null ? (
                    <img
                      src={application.personal_info.profile_picture}
                      alt="student image"
                    />
                  ) : (
                    <>
                      <img src={GalleryAdd} alt="upload image" />
                      <p>Student's photo</p>
                    </>
                  )}
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-evenly gap-2">
          {/*OTHER INFORMATION  */}
          <div className="w-full border border-gray-400 rounded-lg p-4 flex flex-col gap-4">
            <p className="text-red-500 font-medium text-2xl">
              Other Information
            </p>
            <div className="flex flex-col gap-4">
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">
                  Institution Of Choice
                </p>
                <p className="font-medium">{application.school}</p>
              </span>
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">Course Of Study</p>
                <p className="font-medium">{application.course}</p>
              </span>
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">Financial Option</p>
                <p className="font-medium">{application.financing}</p>
              </span>
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">
                  Application Fee Payment
                </p>
                <p className="font-medium">{application.payment}</p>
              </span>
              <div className="flex justify-between">
                <p className="font-normal text-gray-500">Documents Uploaded</p>
                <div className="flex flex-col gap-2">
                  <span className="flex items-center gap-2">
                    {application.personal_info.waec_submitted === false ? (
                      <div className="w-5 h-5 bg-red-600 rounded-full flex items-center justify-center">
                        <MdClose color="white" />
                      </div>
                    ) : (
                      <img src={GreenTick} alt="green tick" />
                    )}
                    {application.personal_info.waec_submitted === false
                      ? "Not Submitted"
                      : "Submitted"}
                    <p>WAEC</p>
                  </span>
                  <span className="flex items-center gap-2">
                    {application.personal_info.jamb_submitted === false ? (
                      <div className="w-5 h-5 bg-red-600 rounded-full flex items-center justify-center">
                        <MdClose color="white" />
                      </div>
                    ) : (
                      <img src={GreenTick} alt="green tick" />
                    )}
                    {application.personal_info.jamb_submitted === false
                      ? "Not Submitted"
                      : "Submitted"}
                    <p>JAMB</p>
                  </span>
                  <span className="flex items-center gap-2">
                    {application.personal_info.neco_submitted === false ? (
                      <div className="w-5 h-5 bg-red-600 rounded-full flex items-center justify-center">
                        <MdClose color="white" />
                      </div>
                    ) : (
                      <img src={GreenTick} alt="green tick" />
                    )}
                    {application.personal_info.neco_submitted === false
                      ? "Not Submitted"
                      : "Submitted"}
                    <p>NECO</p>
                  </span>
                  <span className="flex items-center gap-2">
                    {application.personal_info.nin_submitted === false ? (
                      <div className="w-5 h-5 bg-red-600 rounded-full flex items-center justify-center">
                        <MdClose color="white" />
                      </div>
                    ) : (
                      <img src={GreenTick} alt="green tick" />
                    )}
                    {application.personal_info.nin_submitted === false
                      ? "Not Submitted"
                      : "Submitted"}
                    <p>NIN</p>
                  </span>
                  <span className="flex items-center gap-2">
                    {application.personal_info.passport_submitted === false ? (
                      <div className="w-5 h-5 bg-red-600 rounded-full flex items-center justify-center">
                        <MdClose color="white" />
                      </div>
                    ) : (
                      <img src={GreenTick} alt="green tick" />
                    )}
                    {application.personal_info.passport_submitted === false
                      ? "Not Submitted"
                      : "Submitted"}
                    <p>Passport Photograph</p>
                  </span>
                  <span className="flex items-center gap-2">
                    {application.personal_info.birth_certificate_submitted ===
                    false ? (
                      <div className="w-5 h-5 bg-red-600 rounded-full flex items-center justify-center">
                        <MdClose color="white" />
                      </div>
                    ) : (
                      <img src={GreenTick} alt="green tick" />
                    )}
                    {application.personal_info.birth_certificate_submitted ===
                    false
                      ? "Not Submitted"
                      : "Submitted"}
                    <p>Birth Certificate</p>
                  </span>
                  <span className="flex items-center gap-2">
                    {application.personal_info.certificate_origin_submitted ===
                    false ? (
                      <div className="w-5 h-5 bg-red-600 rounded-full flex items-center justify-center">
                        <MdClose color="white" />
                      </div>
                    ) : (
                      <img src={GreenTick} alt="green tick" />
                    )}
                    {application.personal_info.certificate_origin_submitted ===
                    false
                      ? "Not Submitted"
                      : "Submitted"}
                    <p>Certificate of Origin</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* HORIZOONTAL LINE */}
          <span className="w-0 sm:w-1 rounded-md bg-gray-300" />

          {/* REFERENCE INFO */}
          <div className="w-full border border-gray-400 rounded-lg p-4 flex flex-col gap-4">
            <p className="text-red-500 font-medium text-2xl">
              Reference Information
            </p>
            <div className="flex flex-col gap-4">
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">Full Name</p>
                <p className="font-medium capitalize">{application.rel_name}</p>
              </span>
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">Email Address</p>
                <p className="font-medium">vistaor@gmail.com</p>
              </span>
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">Phone Number</p>
                <p className="font-medium">{application.phone}</p>
              </span>
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">Profession </p>
                <p className="font-medium">{application.profession}</p>
              </span>
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">Relationship</p>
                <p className="font-medium">{application.relationship}</p>
              </span>
              <span className="flex justify-between">
                <p className="font-normal text-gray-500">Address </p>
                <p className="font-medium">{application.current_address}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </SchoolDashboardLayout>
  );
};

export default AppDetails;
