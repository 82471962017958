// SignIn.js
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AuthContext } from "../../../utils/AuthContext";
import { BASE_URL } from "../../../constants";
import Nav from "../../../containers/Nav/Nav";
import GoogleAuthBackend from "../../../GoogleAuthBackend";
import Footer from "../../../containers/Footer/Footer";

const SchoolSignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visibility, setVisibility] = useState(false);

  const history = useNavigate();

  const passwordShow = () => {
    setVisibility((prev) => !prev);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (validated()) {
      try {
        await fetch(`${BASE_URL}school/login/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            school_email_or_name: email,
            password: password,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === false) {
              toast.error(result.message);
            } else {
              toast.success(result.message);
              sessionStorage.setItem("email", result.data.email);
              sessionStorage.setItem("schoolName", result.data.school_name);
              localStorage.setItem("schoolToken", result.data.token);

              // Redirecting to the dashboard or another page upon successful sign-in
              history("/school-dashboard");
            }
          })
          .catch((error) => console.error(error));
      } catch (error) {
        toast.error("Error during sign-in:", error);
      }
    }
  };

  const validated = () => {
    let result = true;
    let errorMessage = "Please enter a value in ";
    if (email === null || email === "") {
      result = false;
      errorMessage += "email";
    }
    if (password === null || password === "") {
      result = false;
      errorMessage += " password";
    }
    if (!result) {
      toast.warning(errorMessage);
    }
    return result;
  };

  return (
    <>
      <Nav url={"/school-signup"} />
      <div className="hero">
        {/* HERO-TEXT */}
        <div className="hero-text">
          <h1>
            Admission <br />{" "}
            <span className="red-text small-text"> and Financing </span> <br />
            <span className="small-text">Processing AI Portal</span>
          </h1>
          {/* <GoogleAuthBackend /> */}
        </div>

        {/* FORM */}
        <form className="hero-form max-w-[340px] sm:w-[360px] min-h-[452px] flex-shrink-0 rounded-3xl bg-white p-6">
          <p className="red-text text-center mb-6 font-medium text-2xl">
            Login Here
          </p>
          <div className="input-container flex flex-col gap-2 mb-2">
            <label htmlFor="email">Email address Or School Name</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <p className="error-message"></p>
          </div>
          <div className="input-container">
            <label htmlFor="password">Password</label>
            <span className="password-visibility">
              <input
                type={visibility ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {!visibility ? (
                <>
                  <FaEye onClick={() => passwordShow()} cursor={"pointer"} />{" "}
                </>
              ) : (
                <>
                  <FaEyeSlash
                    onClick={() => passwordShow()}
                    cursor={"pointer"}
                  />{" "}
                </>
              )}
            </span>
            <p className="error-message"></p>
          </div>
          <div className="forgot_div">
            <div className="check-div">
              <input type="checkbox" className="check-box" />
              <p className="remember">Remember me</p>
            </div>
            {/* link for forgot password /forgot-password */}
            <Link to="/forgot-password" className="underlined">
              Forgot Password
            </Link>
          </div>
          <div className="login-btn">
            <button onClick={handleSignIn} className="small-btn">
              Login
            </button>
            <p>
              Don't have an account? <br />
              {/* link to sign up /signup */}
              <Link to="/school-signup" className="underlined red-text">
                Sign up
              </Link>{" "}
              here
            </p>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default SchoolSignIn;
