// AuthContext.js (or useAuth.js)

import { createContext, useContext, useEffect, useState } from "react";
import { BASE_URL } from "../constants";

export const AuthContext = createContext();
export const UserContext = createContext({
  profileData: null,
  setProfileData: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null); // Add token state
  const [schoolToken, setSchoolToken] = useState(null); // Add token state
  const [profileData, setProfileData] = useState(null); // Add state for profile data

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    const storedSchoolToken = localStorage.getItem("schoolToken");
    if (storedSchoolToken) {
      setSchoolToken(storedSchoolToken);
    }
  }, []);

  useEffect(() => {
    // Call your API to fetch profile data on component mount or token change
    if (token) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Token ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BASE_URL}user/profile/`, requestOptions)
        .then((response) => response.json()) // Assuming JSON response from Django
        .then((data) => {
          setProfileData(data.data);
        })
        .catch((error) => console.error(error));
    }
  }, [token]); // Update profile data on token change

  const signIn = (userData, authToken, schoolToken) => {
    setUser(userData);
    setToken(authToken);
    setSchoolToken(schoolToken);
    localStorage.setItem("schoolToken", schoolToken); // Save token to local storage
    localStorage.setItem("authToken", authToken); // Save token to local storage
  };

  const signOut = () => {
    setUser(null);
    setToken(null); // Clear the token when signing out
    setSchoolToken(null);
    localStorage.removeItem("schoolToken"); // Remove token from local storage
    localStorage.removeItem("authToken"); // Remove token from local storage
    sessionStorage.removeItem("schoolName"); // Remove schoolname from session storage
    sessionStorage.removeItem("email"); // Remove email from session storage
  };

  const value = {
    user,
    token,
    schoolToken,
    signIn,
    signOut,
    profileData, // Add profileData to context value
    setProfileData, // Add setProfileData function
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }
//   return context;
// };
