import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AccountSetting from "../../containers/AccountSetting/AccountSetting";
import SchoolDashboardLayout from "../../layout/SchoolDashboardLayout";

const SchoolProfile = () => {
  const history = useNavigate();
  return (
    <SchoolDashboardLayout title={"School Profile"}>
      {/* <div className="flex"> */}
        <div className="margleft">
          <div className="dashboard">
            <AccountSetting />
          </div>
        </div>
      {/* </div> */}
    </SchoolDashboardLayout>
  );
};

export default SchoolProfile;
