import React, { useState, useContext } from "react";
import Laptop from "../../assets/images/laptop.png";
import bank from "../../assets/images/bank.png";
import health from "../../assets/images/health.png";
import living from "../../assets/images/living.png";
import phone from "../../assets/images/phone.png";
import miscellaneous from "../../assets/images/miscellaneous.png";
import checkmark from "../../assets/images/checkmark.png";
import "./EducationLoans.css";

const dataValue = [
  {
    name: "Health Insurance",
    icon: health,
    key: "0",
  },
  {
    name: "New Laptop",
    icon: Laptop,
    key: "1",
  },
  {
    name: "New Phone",
    icon: phone,
    key: "2",
  },
  {
    name: "Tuition",
    icon: bank,
    key: "3",
  },
  {
    name: "Miscellaneous Cost",
    icon: miscellaneous,
    key: "4",
  },
  {
    name: "Cost of living",
    icon: living,
    key: "5",
  },
];

const EducationLoans = ({ formData, setFormData, handleNextPage }) => {
  const [clickedOptions, setClickedOptions] = useState(
    dataValue.map(() => false)
  );

  const [selectedIndex, setSelectedIndex] = useState(null); // Track selected index

  const loanClick = (index) => {
    if (selectedIndex !== null && selectedIndex !== index) {
      // Deselect previous option (if any)
      const newClickedOptions = [...clickedOptions];
      newClickedOptions[selectedIndex] = false;
      setClickedOptions(newClickedOptions);
    }

    setSelectedIndex(index); // Update selected index

    // Access selected data
    const selectedLoan = dataValue[index];

    // Log and update formData
    console.log("Selected Loan:", selectedLoan.name);
    setFormData((prevData) => ({
      ...prevData,
      loan_type: selectedLoan.name, // Update formData with single selected loan name
    }));
  };

  return (
    <div className="sign-up-container">
      <div className="app_loan">
        <h2 className="font-semibold text-left sm:font-medium text-xl sm:text-3xl">
          Our Education Loans covers the following
        </h2>
        <p className="text-left text-lg mb-4">
          Choose the expense you would love ProconnectPAY to cover
        </p>
        <div className="loan_div">
          {dataValue.map((data, index) => (
            <div
              key={index}
              className={`loan_options ${
                selectedIndex === index ? "clicked" : ""
              }`}
              onClick={() => loanClick(index)}
            >
              <img src={data.icon} alt="" />
              <p className="font-semibold">{data.name}</p>
              {selectedIndex === index && (
                <span className="checked">
                  <img src={checkmark} alt="" />
                </span>
              )}
            </div>
          ))}
        </div>
        <button className="wide-btn btn mt-8" onClick={() => handleNextPage()}>
        Next
      </button>
      </div>
    </div>
  );
};

export default EducationLoans;
