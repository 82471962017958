// TableLayout.js
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";

export default function TableLayout({ columns, rowData }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatDate = (dateTimeString) => {
    return dateTimeString.substring(0, 10);
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case "Pending":
        return { bgcolor: "#FFEC9A", color: "#FFAF00" };
      case "Declined":
        return { bgcolor: "#DB251A1a", color: "#DB251A" };
      case "Approved":
        return { bgcolor: "#D5F4EA", color: "#2A6350" };
      default:
        return { bgcolor: "#db251a1a", color: "#db251a" };
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data found yet
                </TableCell>
              </TableRow>
            ) : (
              rowData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "full_name" ? (
                              <div className="flex items-center capitalize">
                                <Avatar
                                  sx={{ mr: 1 }}
                                  alt={value}
                                  src="/path/to/image.jpg"
                                />
                                {value}
                              </div>
                            ) : column.id === "status" ? (
                              (() => {
                                const { bgcolor, color } =
                                  getStatusStyles(value);
                                return (
                                  <Button
                                    variant="secondary"
                                    sx={{
                                      bgcolor: bgcolor,
                                      color: color,
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {value}
                                  </Button>
                                );
                              })()
                            ) : column.id === "action" ? (
                              <Button
                                variant="primary"
                                sx={{
                                  bgcolor: "#db251a1a",
                                  color: "#db251a",
                                  textTransform: "capitalize",
                                }}
                                // onClick={() => onActionClick(row.app_id)}
                              >
                                <Link to={`/applications/${row.app_id}`}>
                                  View Details
                                </Link>
                              </Button>
                            ) : column.id === "date" ? (
                              formatDate(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50, 100]}
        component="div"
        count={rowData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
