import { useState } from "react";

const OlevelCombo = ({ formData, setFormData, handleNextPage }) => {
  const [typeOfExam, setTypeOfExam] = useState("");

  return (
    <div className="sign-up-container">
      <div className="app_olevel">
        <h2 className="font-semibold text-left sm:font-medium text-xl sm:text-3xl">
          Choose your O'level Combination
        </h2>
        <div className="olevel_form">
          <div className="olevel_details">
            <label htmlFor="tpeOfExam">Choose an option</label>
            <select
              name="tpeOfExam"
              id="typeOfExam"
              onChange={(event) => setTypeOfExam(event.target.value)}
              className="bg-white"
            >
              <option value="">--Select type of Exam--</option>
              <option value="1">WAEC 5 Credit One Sitting</option>
              <option value="2">NECO 5 Credit One Sitting</option>
              <option value="3">WAEC 5 Credit Two Sitting</option>
              <option value="4">NECO 5 Credit Two Sitting</option>
              <option value="5">WAEC & NECO 5 Credit Two Sitting</option>
            </select>
          </div>
        </div>
        <button className="wide-btn btn mt-8" onClick={() => handleNextPage()}>Next</button>
      </div>
    </div>
  );
};

export default OlevelCombo;
