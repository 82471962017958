import React, { useContext, useEffect, useState } from "react";
import Exclamation from "../../assets/images/exclaim.png";
import "./Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../utils/AuthContext";
import { BASE_URL } from "../../constants";

const Dashboard = () => {
  const history = useNavigate();
  const [profileCompleted, setProfileCompleted] = useState(false);

  const { token } = useContext(AuthContext);
  const appId = localStorage.getItem("applicationId");
  const profileStatus = localStorage.getItem("profileStatus");

  // useEffect(() => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Token ${token}`);

  //   const requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${BASE_URL}application/validate-pay/?reference=${appId}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => console.log(result))
  //     .catch((error) => console.error(error));
  // }, []);

  useEffect(() => {
    if (profileStatus) {
      setProfileCompleted(true);
    }
  }, []);

  return (
    <div className="flex">
      <div className="margleft">
        <div className="dashboard">
          <div className="complete-application">
            <img src={Exclamation} alt="" />
            <p>
              {profileCompleted
                ? "Profile Status Pending"
                : "Complete your profile before you begin your application"}
            </p>
          </div>
          {profileCompleted ? (
            ""
          ) : (
            <div className="application_div">
              <Link to="/school-application">
                <span className="application_box">
                  Start School Application
                </span>
              </Link>
              <Link to="/school-application/?page=17" >
                <span className="application_box">
                  Skip School Application To Payment
                </span>
              </Link>
            </div>
          )}
          <div className="dashboard_lower">
            <p className="explainer">Watch Explainer Video</p>
            <video src="" controls className="explainer-video"></video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
