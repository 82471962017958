import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../utils/AuthContext";
import { BASE_URL } from "../../constants";

const NameOfUni = ({ formData, setFormData, handleNextPage }) => {
  const { user, token, signIn, signOut, profileData, setProfileData } =
    useContext(AuthContext);
  const [institutionNames, setInstitutionNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // // Check if data is available in localStorage
        // const storedData = localStorage.getItem("institutionNames");

        // if (storedData) {
        //   // If data is available, use it
        //   setInstitutionNames(JSON.parse(storedData));
        // } else {
        // If data is not available, make an API request
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `${BASE_URL}fetch_school/?school_type=private_university`, // Dynamically change the endpoint based on schoolType
          requestOptions
        );
        const result = await response.json();
        const { data } = result;
        const names = data.map((school) => school.school_name);
        // Store the fetched data in localStorage
        localStorage.setItem("institutionNames", JSON.stringify(names));

        // Update state with the fetched data
        setInstitutionNames(names);
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  const handleChange = (event) => {
    const selectedSchool = event.target.value;
    setFormData((prevData) => ({ ...prevData, school: selectedSchool })); // Update formData with school
  };

  return (
    <div className="app_apply">
      <h2 className="font-semibold text-left sm:font-medium text-xl sm:text-3xl">
        Name of Institutions we support
      </h2>
      <div className="uniType flex flex-col gap-2">
        <label htmlFor="">Name</label>
        <select
          name="institution names"
          id="institution"
          onChange={handleChange}
        >
          <option value="">--Select an institution--</option>
          {institutionNames.map((uni, index) => (
            <option key={uni} value={uni}>
              {uni}
            </option>
          ))}
        </select>
      </div>
      <button className="wide-btn btn mt-8" onClick={() => handleNextPage()}>
        Next
      </button>
    </div>
  );
};

export default NameOfUni;
