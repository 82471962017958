import React, { useContext, useEffect, useState } from "react";
import TableLayout from "../../containers/TableLayout";
import SchoolDashboardLayout from "../../layout/SchoolDashboardLayout";
import { AuthContext } from "../../utils/AuthContext";
import { BASE_URL } from "../../constants";
import { useNavigate } from "react-router-dom";

function createData(serial, name, institution, course, date, action) {
  return { serial, name, institution, course, date, action };
}
const columns = [
  { id: "id", label: "S/N", minWidth: 100 },
  { id: "full_name", label: "Student Name", minWidth: 100 },
  { id: "school", label: "Institution", minWidth: 170, align: "center" },
  { id: "course", label: "Course", minWidth: 170, align: "right" },
  { id: "date", label: "Date", minWidth: 100, align: "right" },
  // { id: "status", label: "Status", minWidth: 100, align: "center" },
  { id: "action", label: "Action", minWidth: 100, align: "center" },
];

const StudentDashboard = () => {
  const [rowData, setRowData] = useState([]);
  const { schoolToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();

  useEffect(() => {
    if (schoolToken !== null) {
      setLoading(false);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Token ${schoolToken}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BASE_URL}school/applications/`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setRowData(result.data);
        })
        .catch((error) => console.error(error));
    }
  }, [schoolToken]);

  // Handle action button click
  const handleActionClick = (appId) => {
    history(`/applications/${appId}`);
  };

  return (
    <SchoolDashboardLayout title={"School Dashboard"}>
      <div className="margleft dashboard">
        <p className="doc-header mb-8">Recent Applications</p>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <TableLayout rowData={rowData} columns={columns} />
        )}
      </div>
    </SchoolDashboardLayout>
  );
};

export default StudentDashboard;
