import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ReferenceInfo = ({ formData, setFormData, handleNextPage }) => {
  const initialValues = {
    contactno: "",
    country_code: "",
  };
  const [referenceData, setReferenceData] = useState(initialValues);

  const handlePhoneChange = (e, value, name) => {
    console.log("value", value);
    console.log("name", name);
    if (name === "contactno") {
      let splitMobile = e?.split(value?.dialCode);
      const newContactno = splitMobile?.[1] || "";
      setReferenceData({
        ...referenceData,
        country_code: value?.dialCode,
        contactno: newContactno,
      });
      setFormData({
        ...formData,
        phone: `${value?.dialCode}${newContactno}`,
      });
    } else {
      setReferenceData({ ...referenceData, [e.target.name]: e.target.value });
    }
  };

  const handleChange = (event) => {
    const selectedRelative = event.target.value;
    console.log("Selected Relative:", selectedRelative);
    setFormData((prevData) => ({
      ...prevData,
      relationship: selectedRelative,
    }));
  };

  return (
    <div className="sign-up-container">
      <div className="app_olevel">
        <h2 className="font-semibold text-left sm:font-medium text-xl sm:text-3xl">
          Fill Reference Information
        </h2>
        <div className="olevel_form">
          <div className="olevel_details">
            <label htmlFor="" className="text-lg">
              Referee's Full Name
            </label>
            <input
              type="text"
              value={formData.rel_name}
              placeholder="Enter referee's full name"
              onChange={(event) =>
                setFormData({ ...formData, rel_name: event.target.value })
              }
            />
          </div>
          <div className="olevel_details">
            <label htmlFor="" className="text-lg">
              Email Address of Referee
            </label>
            <input
              type="email"
              value={formData.email_address}
              placeholder="Enter a valid email address"
              onChange={(event) =>
                setFormData({ ...formData, email_address: event.target.value })
              }
            />
          </div>
          <div className="olevel_details">
            <label htmlFor="" className="text-lg">
              Phone Number
            </label>
            <PhoneInput
              country="ng"
              regions={"africa"}
              inputStyle={{
                height: "44px",
                width: "100%",
                borderRadius: "8px",
              }}
              buttonStyle={{
                backgroundColor: "white",
                borderRadius: "8px 0 0 8px",
                borderColor: "#66666659",
              }}
              onChange={(e, phone) => handlePhoneChange(e, phone, "contactno")}
              value={`${referenceData.country_code}${referenceData.contactno}`}
            />
          </div>
          <div className="olevel_details">
            <label htmlFor="" className="text-lg">
              Profession
            </label>
            <input
              type="text"
              value={formData.profession}
              onChange={(event) =>
                setFormData({ ...formData, profession: event.target.value })
              }
              placeholder="Example: Teacher"
            />
          </div>
          <div className="olevel_details mb-12">
            <label htmlFor="" className="text-lg">
              Relationship
            </label>
            <select name="" id="" onChange={handleChange}>
              <option value="" className="font-bold">
                --Select relationship--
              </option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Uncle">Uncle</option>
              <option value="Aunty">Aunty</option>
            </select>
          </div>
        </div>

        {/* <button className="wide-btn btn" onClick={sendReference}>
          Send Reference Request
        </button> */}
        <button className="wide-btn btn" onClick={() => handleNextPage()}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ReferenceInfo;
