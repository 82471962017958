import { useState } from "react";
import "./Hero.css";
import { Link } from "react-router-dom";
import SignIn from "../../components/SignIn";
import GoogleSignInButton from "../../../GoogleSignInButton";
import GoogleAuth from "../../components/GoogleAuth";
import axios from "axios";
import GoogleAuthBackend from "../../GoogleAuthBackend";

const Hero = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="hero">
      {/* HERO-TEXT */}
      <div className="hero-text">
        <h1>
          Admission <br />{" "}
          <span className="red-text small-text"> and Financing </span> <br />
          <span className="small-text">Processing AI Portal</span>
        </h1>
        {/* <GoogleSignInButton /> */}
        {/* <GoogleAuth /> */}
        <GoogleAuthBackend />
      </div>

      {/* FORM */}
      <SignIn />
    </div>
  );
};

export default Hero;
